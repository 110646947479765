const container = {
  height: "100%",
  width: "100%",
  bg: "white",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
};

const wrapper = {
  pt: ["xx", "xx", "xx", "xx", "4rem", "4rem", "4rem"],
  px: ["xl", "xl", "xl", "xl", "2.5rem", "2.5rem", "2.5rem"],
  maxWidth: "1540px",
  flexGrow: "1",
  width: "100%",
};

const sideNavWrapper = {
  pt: ["lg", null, null, null, "xl", "xl"],
};

const mainWrapper = {
  pt: ["lg", null, null, null, "lg", "xl"],
};

const searchFieldWrapper = {
  py: ["xl", "xl", "xl", "xl", "2rem", "2rem", "2rem"],
  maxWidth: "1540px",
  flexGrow: "1",
  width: "100%",
};

const headerFooterWrapper = {
  p: ["lg", "lg", "lg", "lg", "2.5rem", "2.5rem"],
};

const badgeButton = {
  mr: "sm",
  py: 0,
  alignSelf: "center",
};

const badgeButtonIcon = {
  color: "green-900",
  cursor: "pointer",
};

const truncateText = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: "body",
};

const badgeTextProps = {
  ...truncateText,
  lineHeight: "1",
  fontSize: "sm",
};

const commonLoadingStyle = {
  opacity: 0.7,
  animation: "loading 1.5s linear infinite alternate",
  borderRadius: "0.125rem",
};

const viewListBtn = {
  py: ".75rem",
  px: "0",
  lineHeight: "body",
};

export const globalSX = {
  container,
  wrapper,
  sideNavWrapper,
  mainWrapper,
  searchFieldWrapper,
  headerFooterWrapper,
  badgeButton,
  badgeButtonIcon,
  badgeTextProps,
  commonLoadingStyle,
  viewListBtn,
};
