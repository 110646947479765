// api constants

import { Item } from "types/itemsHooksTypes";

export const ERROR_DEFAULTS = {
  id: "unknown_error_id",
  error: "Unknown Error",
  message: "An error occurred",
};

export const API_TIMEOUT = 10000;

export const NETWORK_ERROR_CODES = [
  "ERR_NETWORK",
  "ERR_CONNECTION_REFUSED",
  "ECONNABORTED",
];

export const DEBOUNCE_DEFAULT_DELAY = 1000;

export const DUMMY_ITEM_LIST = [
  { id: `${Math.random()}` } as Item,
  { id: `${Math.random()}` } as Item,
  { id: `${Math.random()}` } as Item,
];

export type Language =
  | "css"
  | "markup"
  | "bash"
  | "clike"
  | "c"
  | "cpp"
  | "javascript"
  | "jsx"
  | "coffeescript"
  | "actionscript"
  | "css-extr"
  | "diff"
  | "git"
  | "go"
  | "graphql"
  | "handlebars"
  | "json"
  | "less"
  | "makefile"
  | "markdown"
  | "objectivec"
  | "ocaml"
  | "python"
  | "reason"
  | "sass"
  | "scss"
  | "sql"
  | "stylus"
  | "tsx"
  | "typescript"
  | "wasm"
  | "yaml";

export const FILTER_QUERY_PARAM = "filter";

export const PING_IDENTITY_URL = "https://pingidentity.com";
