import { Box, Loader } from "@pingux/astro";
import { COLORS } from "@assets/styles/styleConstants";
import "./index.scss";

interface PageLoaderProps {
  loaderOverlayStyle?: Record<string, any>;
}

const PageLoader = (props: PageLoaderProps) => {
  const { loaderOverlayStyle = {} } = props;
  return (
    <Box
      className="overlay"
      isRow
      alignItems="center"
      justifyContent="center"
      sx={loaderOverlayStyle}
    >
      <Loader size={"sm"} color={COLORS.gray700} />
    </Box>
  );
};

export default PageLoader;
